const data = [
  {
    id: 1,
    name: "Valdo Media Agency",
    description:
      "Valdo Media Agency is a dynamic wordpress application, Plugin used: ACF, Contact Form 7, Flamingo",
    github: "https://github.com/rofrado9178/valdomedia",
    image: "./images/valdomedia.png",
    liveSite: "https://fransiskus42.web582.com/valdomedia/",
  },
  {
    id: 2,
    name: "Audience Data API",
    description:
      "This is a booking app, where we can book and count the audience and track it with chart, this app build with PHP, Vanilla Javascript, SQL",
    github: "https://github.com/rofrado9178/Audiences-Data-API-Project",
    image: "./images/booking.png",
    liveSite:
      "https://fransiskus42.web582.com/dynamic/Audiences-Data-API-Project/index.html",
  },
  {
    id: 3,
    name: "Austin Cleaning Services",
    description:
      "This is a static website for Austin Cleaning Services, build using Html, css and jquery, i also use fancybox and slick slider for this project",
    github: "https://github.com/rofrado9178/Cleaning-Services/tree/main",
    image: "./images/austin.png",
    liveSite:
      "https://fransiskus42.web582.com/BLOCK-02/Web-Creation-2/Assignment/Final-Project/index.html",
  },
  {
    id: 4,
    name: "React-Todo",
    description: "This is a front-end Todo List app, build with react",
    github: "https://fransiskus42.web582.com/todo-react/dist/index.html",
    image: "./images/todo-react.png",
    liveSite: "https://fransiskus42.web582.com/todo-react/dist/index.html",
  },
];

export default data;
