import React from "react";
import {
  FaHtml5,
  FaCss3,
  FaSass,
  FaJs,
  FaReact,
  FaPhp,
  FaPython,
  FaDatabase,
  FaWordpress,
  FaFigma,
  FaGithubSquare,
} from "react-icons/fa";

import { SiAdobephotoshop, SiJquery } from "react-icons/si";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

export default function Skill() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const animation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(100px)",
    config: { duration: 1000 },
  });

  return (
    <animated.section className="skills" ref={ref} style={animation}>
      <div className="container">
        <h2>My Skills</h2>

        <div className="skills-container">
          <div className="skill">
            <FaHtml5 className="skill-icon" />
            <p>HTML</p>
          </div>
          <div className="skill">
            <FaCss3 className="skill-icon" />
            <p>CSS</p>
          </div>
          <div className="skill">
            <FaSass className="skill-icon" />
            <p>Sass</p>
          </div>
          <div className="skill">
            <SiJquery className="skill-icon" />
            <p>JQuery</p>
          </div>
          <div className="skill">
            <FaJs className="skill-icon" />
            <p>JavaScript</p>
          </div>
          <div className="skill">
            <FaReact className="skill-icon" />
            <p>React</p>
          </div>
          <div className="skill">
            <FaPhp className="skill-icon" />
            <p>PHP</p>
          </div>
          <div className="skill">
            <FaPython className="skill-icon" />
            <p>Python</p>
          </div>

          <div className="skill">
            <FaDatabase className="skill-icon" />
            <p>SQL</p>
          </div>

          <div className="skill">
            <FaWordpress className="skill-icon" />
            <p>WordPress</p>
          </div>

          <div className="skill">
            <FaFigma className="skill-icon" />
            <p>Figma</p>
          </div>

          <div className="skill">
            <SiAdobephotoshop className="skill-icon" />
            <p>Photoshop</p>
          </div>

          <div className="skill">
            <FaGithubSquare className="skill-icon" />
            <p>Github</p>
          </div>
        </div>
      </div>
    </animated.section>
  );
}
