import { FaGithubSquare } from "react-icons/fa";

export default function Card({ props }) {
  const { name, description, github, image, liveSite } = props;

  return (
    <article className="card-flex">
      <img src={image} alt="" className="project-img" />
      <aside className="project-description">
        <h3 className="project-title">{name}</h3>
        <p>{description}</p>

        <a href={github}>
          <FaGithubSquare className="github"> </FaGithubSquare>
        </a>
        <a href={liveSite} target="_blank" rel="noreferrer">
          Live site
        </a>
      </aside>
    </article>
  );
}
