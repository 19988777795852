import Contact from "./Contact";
const Bio = () => {
  return (
    <div className="container">
      <div className="bio-flex">
        <Contact />
        <aside className="bio-block"></aside>
        <aside className="bio-description">
          <h1 className="title">My name is Fransiskus Aldo</h1>
          <p className="p-developer">I am a web developer</p>
          <p className="p-welcome">Welcome to my website</p>
        </aside>
      </div>
    </div>
  );
};

export default Bio;
