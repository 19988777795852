import Card from "./Card";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

const CardList = ({ data }) => {
  const projects = data.map((project) => {
    return <Card props={project} />;
  });

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const animation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(100px)",
    config: { duration: 800 },
  });

  return (
    <animated.section ref={ref} style={animation}>
      <div className="container">{projects}</div>
    </animated.section>
  );
};

export default CardList;
