import "./App.css";
import Bio from "./components/Bio";
import CardList from "./components/CardList";
import Footer from "./components/Footer";
import Skill from "./components/Skill";

import data from "./data";

function App() {
  return (
    <main className="App">
      <article className="container-wrap">
        <section className="background">
          <Bio />
        </section>

        <CardList data={data} />
        <Skill />
      </article>
      <Footer />
    </main>
  );
}

export default App;
