const Footer = () => {
  return (
    <section className="footer">
      <aside className="footer-container">
        <p className="copyright"> &copy; 2024 Fransiskus Aldo</p>
      </aside>
    </section>
  );
};

export default Footer;
